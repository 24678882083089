<template>
<div>
    <!-- <KpiHeader
        v-if="location"
        title="Country Overview"
        :location="location"
        :tabs=null
        :disable_title_meta="true"
    /> -->
    <!-- <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"

        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :bridge-settings="table.bridge_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'Country': { asc: true } }"
        class="mt ms1p5"
    /> -->
    <img class="assembled" src="/assets/images/presentation/being-assembled.png" />
</div>
</template>

<script>
import KpiHeader from '@/components/ui/KPIheader.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';

export default {
    components: {
        KpiHeader,
        Table,
    },
    data: function() {
        return {
            location: '/country-overview',
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            // Array of named home page kpis in order
            // KPIs with // are included in data, those without are missing
            // kpi_order: [ this is the previous order kept incase a reversal is desired - Ben 19 Oct 21
            //     'Accessibility',
            //     'Positive Impact',
            //     'Affordability',

            //     'Brand Desire', //
            //     'Brand Trust',

            //     'Total Sales Dev', //
            //     'Comp Cash & Carry', //
            //     'Online', //
            //     'Store Visits (comp)',
            //     'Online Visits', //
            //     'Online Conversion Rate',
            //     'Market Share',
            //     'Product Availability OSA', //
            //     'Product Availability CDC', //

            //     'Happy Customer Topline', //
            //     'Happy Customer Physical', //
            //     'Happy Customer Digital', //
            //     'Happy Customer Support', //
            //     'Happy Customer Services', //

            //     'Operational GM', //
            //     'Operational Cost Before Global Allocations, Excl. Portfolios and AO', //
            //     'Operational Result Before Global Allocations, Excl. Portfolios and AO', //

            //     'Leadership Index', //
            //     'Engagement Index', //
            //     'IKEA Values', //
            //     'Co-Worker Turnover', //
            //     'Gender Share', //

            //     'People + Planet Positive Sales', //
            //     'Climate Footprint', //

            //     'Sales planning Precision',
            //     'Purchase Order Compliance' //
            // ]

            kpi_order: [
                'Positive Impact',
                'Affordability',
                'Penetration',

                'Brand Trust',

                'Store Visits (comp)',
                'Online Visits', //
                'Online Conversion Rate',
                'Market Share',
                'Product Availability OSA', //
                'Product Availability CDC', //

                'Happy Customer Topline', //
                'Happy Customer Physical', //
                'Happy Customer Digital', //
                'Happy Customer Support', //
                'Happy Customer Services', //

                'Operational GM', //
                'Operational Cost Before Global Allocations, Excl. Portfolios and AO', //
                'Operational Result Before Global Allocations, Excl. Portfolios and AO', //

                'Leadership Index', //
                'Engagement Index', //
                'IKEA Values', //
                'Co-Worker Turnover', //

                'People + Planet Positive Sales', //
                'Climate Footprint', //
            ]
            // Unsued data: Non Value Contacts - Availability - lead times x3 - Operational Gm x2/Operational Gross Margin - Sales tree - quality online - low price in reality - ikea fam share sales - promised delivery precision
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.$store.commit('set_routing', false);
        //this.get_data();
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        get_data: function() {
            this.table.render = false;
            this.$http.get('/country-overview/country').then(
                succ => {
                    let d = succ.data;
                    if (!d || !d.length) return;

                    // Assume 'ALL' market has every possible kpi in it's list.
                    // API returns more kpis than needed, filter and sort to hardcoded list of front page kpis 'this.kpi_order'
                    const raw_kpi_list = d.find(country => country.market_name === 'ALL').measures.kpi_list.kpis;
                    const filtered_kpis = raw_kpi_list.filter(kpi => {
                        if (!kpi || !kpi.area_name) return '';
                        return this.kpi_order.includes(kpi.area_name.split(':')[1]);
                    });
                    const filtered_sorted_kpis = filtered_kpis.sort((a,b) => {
                        const a_index = this.kpi_order.indexOf(a.area_name.split(':')[1]);
                        const b_index = this.kpi_order.indexOf(b.area_name.split(':')[1]);
                        return a_index > b_index ? 1 : -1;
                    });
                    
                    // Generate headers - kpi column headers also contain some data 'countries_above_goal' & 'countries_positive_trends'
                    const all_kpis = [];
                    const kpi_headers = [];
                    const countries_above_goal = [];
                    const countries_positive_trends = [];
                    const null_check = (val) => val === null ? 'N/A' : val;
                    filtered_sorted_kpis.forEach(kpi => {
                        all_kpis.push(kpi.area_name);
                        const kpi_name = kpi.area_name.split(':')[1];
                        kpi_headers.push({[kpi_name]: 3});
                        countries_above_goal.push({[null_check(kpi.countries_above_goal)]: 3});
                        countries_positive_trends.push({[null_check(kpi.countries_positive_trends)]: 3});
                    });

                    let all_kpi_subheaders = [];
                    kpi_headers.forEach((kpi, i) => {
                        all_kpi_subheaders.push(
                            {'Value': 1},
                            {'vs goal': 1, alias: `${Object.keys(kpi)[0]} vs goal`},
                            {'Trend': 1, alias: `${Object.keys(kpi)[0]} Trend`}
                        );
                    });

                    this.table.headers = [
                        // Row 1
                        [{'': 1}, {'Overall (data for all KPIs even those not shown)': 2}, ...kpi_headers],
                        // Row 2
                        [{'': 1}, {'Countries above goal': 2}, ...countries_above_goal],
                        // Row 3
                        [{'': 1}, {'Countries with positive trend': 2}, ...countries_positive_trends],

                        // Row 4
                        [
                            {'': 1},

                            {"Goals reached": 1},
                            {'Nr. of positive trends': 1},

                            ...all_kpi_subheaders
                        ]
                    ];
                    
                    this.table.bridge_settings = {
                        columns: {
                            'Overall': { hide: true },
                        }
                    }
                    kpi_headers.forEach(header => {
                        this.table.bridge_settings['columns'][Object.keys(header)[0]] = { hide: true }
                    });
                    // this.table.bridge_settings = {
                    //     columns: {
                    //         'Overall': { hide: true },
                    //         'Physical': { hide: true },
                    //         'Digital': { hide: true },
                    //         'Support': { hide: true },
                    //         'Services': { hide: true }
                    //     }
                    // }

                    // Replace trend 1/0/-1 with arrow icons
                    let icons = {};
                    
                    kpi_headers.forEach(kpi => {
                        icons[`${Object.keys(kpi)[0]} Trend`] = {
                            icon: 'arrow-only',
                            comparison: 0,
                        };
                        icons[`${Object.keys(kpi)[0]} vs goal`] = {
                            icon: 'circle',
                            comparison: 0,
                        };
                    });
                    this.table.icon_settings = {...icons};

                    // Insert each kpi's data, noting that the order of the 'data' array is not guarenteed so 'find' is used to ensure order
                    this.table.rows = d.map(row => {
                        let temp = [row.caption];
                        temp.push(this.$helper.maybe(row.measures.overall.goals_reached));
                        temp.push(this.$helper.maybe(row.measures.overall.positive_trends));
                        
                        all_kpis.forEach(kpi_name => {
                            const kpi_data = row.measures.kpi_list.kpis.find(kpi => kpi.area_name === kpi_name);
                            if (!kpi_data) {
                                temp.push('', '', '');
                                return;
                            }

                            const subheaders = ['metric_value', 'vs_goal', 'trending'];

                            subheaders.forEach(subheader => {
                                const subheader_data_entry = kpi_data.data.find(data_entry => Object.keys(data_entry)[0] === subheader);
                                if (!subheader_data_entry) {
                                    temp.push('');
                                    return;
                                } else if (subheader === 'trending') {
                                    temp.push(this.$helper.maybe(subheader_data_entry[subheader])); //The maybe() func may cause issues if zero should mean neutral trend
                                } else {
                                    temp.push(this.$helper.round(this.$helper.maybe(subheader_data_entry[subheader]), 1));
                                }
                            });
                        });
                        return temp;
                    });
                    this.table.render = true;
                },
                this.$helper.error
            );
        }
    },
    computed :{
        tabs: function() {
            return [
                {name: 'By Country', link: `${this.location}/country`},
                {name: 'KPI List', link: `${this.location}/kpi/${this.$store.getters.market}`},
                {name: 'Benchmark', link: `${this.location}/benchmark`}
            ]
        }
    }
}
</script>

<style>
/* .mt {
    margin-top: 1rem;
} */
 .assembled {
   margin-top: 20vh;
 }
</style>
